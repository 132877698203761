
import React, { useState, useEffect, } from 'react'
import Aos from 'aos';
import './Acp.css';
import pic from "../assets/33.jpg";
import pics from "../assets/35.jpg";

function Acp() {

    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);

useEffect(()=>{
  Aos.init({duration:1000});
},[]);


  return (
    <>
   <div className='back'>
     <div data-aos="fade-right" style={{width:'100%',backgroundColor:'rgba(0,0,0,.5)',display:'flex',color:'white',flexDirection:'column',alignItems:'center',fontFamily:'Montserrat'}}> 
    <h1 style={{letterSpacing:12,fontSize:'7vw',paddingTop:20}}>ACP</h1>
    <h5 style={{paddingBottom:20,borderTop:'1px solid white',paddingTop:15}}>Aluminium Composite Panels</h5>
     </div>
     </div>
     <div style={{backgroundColor:'#f5f7fd',display:'flex',flexDirection:'column',alignItems:'center',textAlign:'center',height:'75vh',justifyContent:'center',padding:25,fontFamily:'Montserrat'}}>
        <h2 style={{paddingBottom:'5vh'}}>SITMO <strong>ACP</strong></h2>
        <p data-aos="zoom-in" style={{color:'#96979b',fontSize:button?'3.00vh':'2.50vh',maxWidth:700,}}>
        ACP is a new age material with ever-growing demand in the building
construction industry. The material offers a widening range of options to
builders, architects, interior and exterior designers considering its various
usages. Sitmos provides safe and budget friendly ACP sheets as ACP panels are
fire resistant and provides high quality products at effective cost.
        </p>
    </div>
    <div style={{fontFamily:'Montserrat',display:'flex',justifyContent:'center',flexDirection:'column',}}>
        <div style={{paddingTop:50,textAlign:'center'}}>
        <h2 style={{fontWeight:'bolder'}}>KEY FEATURES</h2>
        <p style={{letterSpacing:2,paddingBottom:30,fontSize:12,fontStyle:'italic'}}>Natural Finishes Redifined</p>
        <div style={{display:'flex'}}>
{button?<img style={{width:'34%',height:'60vh'}} src={pic}/>:null}
{button?<div style={{width:'32%',backgroundColor:'#f5f9fd',display:'flex',flexDirection:'column',justifyContent:'center',fontSize:'large',paddingBottom:10}}>
<p data-aos="fade-right"  style={{}}>Easy to use & install</p>
<p data-aos="fade-left" >Cost effective</p>
<p data-aos="fade-right" style={{}}>Weather resistant</p>
<p data-aos="fade-left">Unbreakable</p>
<p data-aos="fade-right" style={{}}>Low maintenance</p>
<p data-aos="fade-left">Lightweight</p>
</div>
:
<div style={{width:'100%',backgroundColor:'white',display:'flex',flexDirection:'column',justifyContent:'center',fontSize:'large',paddingBottom:10}}>
<p   style={{}}>Easy to use & install</p>
<p  >Cost effective</p>
<p  style={{}}>Weather resistant</p>
<p >Unbreakable</p>
<p  style={{}}>Low maintenance</p>
<p style={{marginBottom:50}} >Lightweight</p>
</div>
  }
{button?<img style={{width:'34%',height:'60vh'}} src={pics}/>:null}
        </div>
  </div>

    </div>
    </>
  );
}

export default Acp;