
import React, { useState, useEffect, } from 'react'
import Aos from 'aos';
import './Acp.css';
import pic from "../assets/34.jpg";
import pics from "../assets/38.jpg";

function Plywood() {

    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);

useEffect(()=>{
  Aos.init({duration:1500});
},[]);


  return (
    <>
   <div className='back'>
     <div data-aos="fade-right" style={{width:'100%',backgroundColor:'rgba(0,0,0,.5)',display:'flex',color:'white',flexDirection:'column',alignItems:'center',fontFamily:'Montserrat'}}> 
    <h1 style={{letterSpacing:12,fontSize:'5vw',paddingTop:25,paddingBottom:25}}>PLYWOOD</h1>
 
     </div>
     </div>
     <div style={{backgroundColor:'#f5f7fd',display:'flex',flexDirection:'column',alignItems:'center',textAlign:'center',height:'75vh',justifyContent:'center',padding:25,fontFamily:'Montserrat'}}>
        <h2 style={{paddingBottom:'5vh'}}>SITMO <strong>PLYWOOD</strong></h2>
        <p data-aos="zoom-in" style={{color:'#96979b',fontSize:button?'3.00vh':'2.50vh',maxWidth:700,}}>
        When it comes to plywood, Sitmo products are used extensively in commercial and domestic settings. Sitmo plywood is affordable yet durable and offer unmatched strength and style to complement modern, urban living.
        </p>
    </div>
    <div style={{fontFamily:'Montserrat',display:'flex',justifyContent:'center',flexDirection:'column',}}>
        <div style={{paddingTop:50,textAlign:'center'}}>
        <h2 style={{fontWeight:'bolder'}}>KEY FEATURES</h2>
        <p style={{letterSpacing:2,paddingBottom:30,fontSize:12,fontStyle:'italic'}}>Natural Finishes Redifined</p>
        <div style={{display:'flex'}}>
{button?<img style={{width:'34%',height:'60vh'}} src={pic}/>:null}
{button?<div style={{width:'32%',backgroundColor:'#f5f9fd',display:'flex',flexDirection:'column',justifyContent:'center',fontSize:'large'}}>
<p data-aos="fade-right"  style={{}}>High Strength & Dimensional Stability</p>
<p data-aos="fade-left" >Cost effective</p>
<p data-aos="fade-right" style={{}}>High Impact Resistance</p>

</div>:
<div style={{width:'100%',backgroundColor:'white',display:'flex',flexDirection:'column',justifyContent:'center',fontSize:'large'}}>
<p style={{}}>High Strength & Dimensional Stability</p>
<p  >Cost effective</p>
<p  style={{marginBottom:50}}>High Impact Resistance</p>

</div>
}
{button?<img style={{width:'34%',height:'60vh'}} src={pics}/>:null}
        </div>
  </div>

    </div>
    </>
  );
}

export default Plywood;