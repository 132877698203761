import './App.css';
import React, { useState, useEffect, } from 'react'
import  Navigation  from "./Navbar.js";
import  Footer  from "./Footer.js";
import { Switch, Route,BrowserRouter } from "react-router-dom";
import Home from './components/Home';
import Contact from "./components/Contact";
import About from "./components/About";
import Acp from "./components/Acp";
import Hpl from "./components/Hpl";
import Laminates from './components/Laminates';
import Plywood from './components/Plywood';

const  App=()=> {

const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);

  return <div style={{flex:1,}}>  
<BrowserRouter>
<Navigation/>
<Switch>

<Route path='/' exact component={Home} />
<Route path='/about' component={About} />
<Route path='/contact' component={Contact} />
<Route  path='/acp' component={Acp}/>
<Route  path='/hpl' component={Hpl}/>
<Route  path='/laminates' component={Laminates}/>
<Route  path='/plywood' component={Plywood}/>
</Switch>
<Footer></Footer>
</BrowserRouter>
  </div>
}

export default App;
