
import React, { useState, useEffect, } from 'react'
import './About.css';
import pic from "../assets/33.jpg";
import home from '../assets/home.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import building from '../assets/building.png';
import office from '../assets/workplace.png';

function About() {

    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);

useEffect(()=>{
  Aos.init({duration:1500});
},[]);


  return (
    <>
    <div className='about '>
        <h1  data-aos="zoom-in" style={{color:'white',fontWeight:'bolder',letterSpacing:3,fontSize:60,fontFamily:'Montserrat'}}>ABOUT US</h1>
  </div>
    <div style={{backgroundColor:'#f5f7fd',fontFamily:'Montserrat',display:'flex',flexDirection:'column',alignItems:'center',textAlign:'center',height:'85vh',justifyContent:'center',padding:25}}>
        <h2 style={{fontWeight:'bolder',paddingBottom:'2.5vh'}}>Who We Are</h2>
        <p style={{paddingBottom:'2.5vh',letterSpacing:2,color:'#777',fontWeight:'bolder',fontSize:12}}>- WE BUILD THE FUTURE -</p>
        <p data-aos="zoom-in" style={{color:'#96979b',fontSize:button?'3.00vh':'2.50vh',maxWidth:700,}}>
  Sitmo products are helping create the new vibrant face of the Indian millennium. Sitmo crew is diligent and passionate towards creating a high quality products and valuable customer satisfaction. Our superior quality products are developed through continuous R&D, keeping in mind the changing taste & preferences of the end consumer.

        </p>
    </div>
    <div style={{fontFamily:'Montserrat',display:'flex',textAlign:'left',}}>
    {button?<img style={{width:'70%',height:'100vh'}} src={pic}></img>:null}
        <div style={{padding:button?'6vh':'5vh'}}>
        <h2 style={{fontWeight:'bolder',}}>What We Do</h2>
        <p style={{fontWeight:'bolder',fontSize:'large',color:'grey'}}>Luxurious and Authentic</p>
        <div data-aos="zoom-in-up" style={{paddingTop:50,display:'flex',flexDirection:'row'}}>
            <img style={{height:75,width:75,}} src={building}></img>
            <div style={{paddingLeft:25}}>
            <h5 style={{fontWeight:'bold',fontSize:'large',}}>Commercial</h5>
            <p style={{color:'#96979b',}}>Sitmo products are helping create the new vibrant face of the Indian millennium. </p>
            </div>
        </div>
        <div data-aos="zoom-in-up" style={{paddingTop:50,display:'flex',flexDirection:'row'}}>
            <img style={{height:75,width:75,}} src={home}></img>
            <div style={{paddingLeft:25}}>
            <h5 style={{fontWeight:'bold',fontSize:'large'}}>Resedential</h5>
            <p style={{color:'#96979b'}}>Sitmo products are helping create the new vibrant face of the Indian millennium. </p>
            </div>
        </div>
        <div data-aos="zoom-in-up" style={{paddingTop:50,display:'flex',flexDirection:'row'}}>
            <img style={{height:75,width:75,}} src={office}></img>
            <div style={{paddingLeft:25}}>
            <h5 style={{fontWeight:'bold',fontSize:'large'}}>Offices</h5>
            <p style={{color:'#96979b'}}>Sitmo products are helping create the new vibrant face of the Indian millennium. </p>
            </div>
        </div>
        </div>
    </div>
    </>
  );
}

export default About;