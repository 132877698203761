
import '../App.css';
import React, { useState, useEffect, } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import quality from "../assets/quality.png";
import collect from "../assets/collect.png";
import fog from "../assets/fog.png";

function Home() {

    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);

useEffect(()=>{
  Aos.init({duration:1500});
},[]);

  return (
    <>
   <div className='bg'>
     <div data-aos="fade-right" style={{maxWidth:'50vw',backgroundColor:'rgba(0,0,0,.6)',marginTop:'20vh'}}> 
    <h1 style={{color:'white',padding:'3vw',letterSpacing:1,fontFamily:'Montserrat',fontSize:button?'6vh':'7vw',fontWeight:'bolder'}}>VISIUALIZE YOUR DESIGN WITH SITMO</h1>
     </div>
  </div>
  <div style={{}}>
<div style={{backgroundColor:'#EDEDED',margin:'3.50vw',textAlign:'center',paddingTop:25}}>
<h3 style={{fontSize:'large',letterSpacing:1,fontFamily:'Arial',fontWeight:600}}>A COMMON VISION</h3>
<h2 style={{fontSize:'medium',fontFamily:'Montserrat',fontWeight:400,padding:button?'15vw':'10vw',paddingTop:20,paddingBottom:25,color:'grey'}}>At Sitmo, we strive to be an innovative organization providing peak level of professional services. Our superior quality products are developed through continuous R&D, catering to the taste of and preferences of the ultimate customer.</h2>
<h1 data-aos="zoom-in" style={{color:'white',letterSpacing:10,fontFamily:'starling',fontSize:50,lineHeight:'33px'}}>SITMO</h1>
</div>
</div>

  <div style={{width:'100%',backgroundColor:'white',paddingTop:25,textAlign:'center'}}>
  <h2 style={{color:'#1B1B1B',fontFamily:'sans-serif',letterSpacing:1,fontSize:30,fontWeight:700,paddingBottom:25,}}>OUR PRODUCTS</h2>

  <div style={{display:'flex',flexDirection:button?'row':'column'}}>
  <a className='products acp' href='/acp'> 
    <h1 style={{}}>ACP</h1>
    </a>
    <a href='/hpl' className='products'>
    <h1 style={{}}>HPL</h1>
    </a>
    <a href='/laminates' className='products lam'>
    <h1 style={{}}>LAMINATES</h1>
    </a>
    <a href='/plywood' className='products ply'>
    <h1 style={{}}>PLYWOOD</h1>
    </a>
  
  </div>
  </div>
  <div style={{width:'100%',backgroundColor:'grey',paddingTop:1,fontFamily:'sans-serif33',color:'white'}}>
    <h2  style={{textAlign:'center',letterSpacing:1,paddingTop:45,paddingBottom:20}}>WHY CHOOSE US</h2>
    <div style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-evenly',paddingBottom:20}}>
      <div data-aos="zoom-in" style={{textAlign:'center',padding:25}}>
        <img src={quality}/>
        <h3 style={{fontSize:'x-large',paddingTop:15}}>Quality Manufacturing</h3>
        <p style={{fontSize:'small'}}>Sitmo Prides itself in a world class Manufacturing facility in India. We Manufacture rigrously tested products using the best raw materials.</p>
      </div>
      <div data-aos="zoom-in" style={{textAlign:'center',padding:25}}>
      <img src={collect}/>
        <h3 style={{fontSize:'x-large',paddingTop:15}}>Wide Range of Products</h3>
        <p style={{fontSize:'small'}}>We offer widest range of ACP, HPL, Laminates and Plywood. Sitmo has been at the forefront of design and has pioneered the introduction of various finishes and shades.</p>
      </div>
      <div data-aos="zoom-in" style={{textAlign:'center',padding:25}}>
      <img src={fog}/>
        <h3 style={{fontSize:'x-large',paddingTop:15}}>Wheather Resistant</h3>
        <p style={{fontSize:'small'}}>Our products undergo a series of tests to ensure durability in any wheather and season. We also provide multi-year warranty on our products.</p>
      </div>
    </div>
  </div>
    </>
  );
}

export default Home;