import './Footer.css';
import React, { useState, useEffect, } from 'react'

const Footer = () => {

    const [button,setButton] = useState(true);

    const showButton = () =>{
      if(window.innerWidth <= 960){
        setButton(false);
      }
      else{
        setButton(true);
      }
    }
    
    useEffect(()=>{
      showButton();
    },[]);
      window.addEventListener('resize',showButton);
    

  return (
      <div >
        <div className='sitmo'>
          <h1 style={{fontSize:'x-large',fontFamily:'Montserrat',letterSpacing:4,}}>DESIGN MATTERS</h1>
        </div>
    <div className={button?'footer':'footer mobile'} style={{paddingTop:25,paddingBottom:25}}> 
    <div style={{width:button?'25%':null,fontFamily:'PT Sans',padding:25}}>
    <h2 style={{color:'gray'}}>About Us</h2>
    <p1 style={{color:'white',fontSize:'medium',}}>Sitmo, proudly introduces rich shades of solids & natural textures for all your exterior and interior needs. With realistic textures and rich dimensions, feel the perfect combination of excellence, warmth…</p1>
    </div>
    <div style={{display:'flex',flexDirection:'column',fontFamily:'PT Sans',padding:25}}>
    <h2 style={{color:'gray'}}>Quick Links</h2>
  <a  href='/'>  <p1  className='link'>Home</p1></a>
  <a  href='/acp'>    <p1 className='link'>Products</p1></a>
  <a  href='/about'>   <p1 className='link'>About Us</p1></a>
  <a  href='/contact'>  <p1 className='link'>Conatct Us</p1></a>
    </div>
    <div style={{display:'flex',flexDirection:'column',fontFamily:'PT Sans',padding:25}}>
    <h2 style={{color:'gray'}}>Our Products</h2>
    <a  href='/acp'>  <p1 className='link'>ACP</p1></a>
    <a  href='/hpl'> <p1 className='link'>HPL</p1></a>
    <a  href='/laminates'> <p1 className='link'>Laminates</p1></a>
    <a  href='/plywood'> <p1 className='link'>Plywood</p1></a>
    </div>
    <div style={{display:'flex',flexDirection:'column',maxWidth:button?'20%':null,color:'white',fontFamily:'PT Sans',padding:25}}>
    <h2 style={{color:'gray'}}>Visit Us</h2>
    <p1 style={{paddingBottom:'3%'}}>Sitmo Corporate Office, Mysore Road, Bengaluru - 560026</p1>
           <p1 className='link'><a href='tel:+918146876222'>+91 8146876222</a></p1>
           <p1 className='link'><a href='tel:+919464530384'>+91 9464530384</a></p1>
        
           <p1 className='link'><a href="mailto:info@sitmopanels.com">info@sitmopanels.com</a></p1>
    </div>
    </div>
    <div style={{height:50,width:'100%',backgroundColor:'black',color:'white',alignItems:'center',display:'flex',justifyContent:'center',fontSize:button?'medium':'small',fontFamily:'PT Sans'}}>
      © copyright {new Date().getFullYear()} Sitmopanels.com, All Rights Reserved
    </div>
    </div>
  );
};

export default Footer;