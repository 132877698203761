
import './contact.css';
import React, { useState, useEffect, } from 'react'
import emailjs from 'emailjs-com';

function Contact() {

    const [button,setButton] = useState(true);


   

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('vega_starz', 'template_6muha2e', e.target, 'user_kksIl46qeLJnfq6gK8KlT')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }


  return (
    <>
     <div style={{backgroundColor:'grey',width:'100%',fontFamily:'sans-serif',color:'white',height:'100%'}}>
       <div  style={{height:'100vh',width:'100%',display:'flex',justifyContent:'space-evenly',alignItems:'center',}}>
      {button? <div className='contact'>
    <h1 style={{}}>CONTACT US</h1>
    </div>:null}
         <div  style={{width:button?'40vw':'85vw'}}>
           <h3 style={{paddingBottom:30,fontWeight:'bold'}} >MAKE YOUR PROJECT A REALITY</h3>
           <h5>Sitmo Corporate Office, Mysore Road, Bengaluru - 560026</h5>
  
           <h5><a href='tel:+919464530384'>+91 9464530384</a></h5>
           <h5><a href='tel:+918146876222'>+91 8146876222</a></h5>
           <h5><a href="mailto:info@sitmopanels.com">info@sitmopanels.com</a></h5>
         </div>
   
    </div>
      <form className="contact-form" onSubmit={sendEmail} style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',backgroundColor:'white',color:'black',paddingBottom:50,}}>
        <h2 style={{fontWeight:'bolder',paddingTop:50,paddingBottom:35}}>GET IN TOUCH</h2>
        <input type="text" name="from_name"  type="text"  className={button?'input':'input mobile'} placeholder="Your Name"></input>
        <input  className={button?'input':'input mobile'} type="text"  name="phone" placeholder="Your Number"></input>
        <input className={button?'input':'input mobile'} type="text" name='email' placeholder="Your Email"></input>
        <textarea name="message" className={button?'input':'input mobile'}  placeholder="Your Message"/>

        <button   style={{width:200,backgroundColor:'#1b1b1b',marginTop:25,height:40,borderRadius:0,letterSpacing:3,color:'white'}} type='submit' class="btn">SEND</button>
      </form>

     </div>

  
    </>
  );
}

export default Contact;